import React from 'react'
import { Link } from 'react-router-dom'
import Toggle from '../Toggle/Toggle'
import './Navbar.css'

function Navbar() {
  return (
    <div className="n-wrapper">
        <div className="n-left">
            <div className="n-name"> Lunar Sound  </div>
            <Toggle/>
        </div>
    
        <div className="n-right">
                <div className="n-list">
                    <ul>
                        <li> <Link to="/"> HOME </Link> </li>
                        <li> <Link to="/projects" > Projects </Link> </li>
                        <li> <Link to="/bio" > Biography </Link> </li>
                        <li> <Link to="/portfolio" > Portfolio </Link></li>
                    </ul>
                </div>
        </div>
    </div>
  )
}

export default Navbar