import React from 'react';
import Footer from '../components/Footer/Footer'
import Bookings from "../components/Bookings/Bookings";
import './pagestyles/Booking.css'

function BookingPage() {
  return (
    <div> 
      <h1 className='bookingHeader'> Book Below </h1>
      <Bookings/>
      <Footer/>
    </div>

  )
}

export default BookingPage