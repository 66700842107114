import React, { useRef } from 'react';
import './Bookings.css'
import emailjs from '@emailjs/browser';

function Bookings() {

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if(document.getElementById("messageField").value.trim() === ''){
      alert("[Error]: Can’t Send With Empty Field, Type in Your Message and Try Again! :)");
    }else{
    emailjs.sendForm('service_90nx5bo', 'template_rjbabkq', form.current, 'xfMFLdkaSfYS41jgk')
      .then((result) => {
        alert("Message sent successfully! Thank You For Reaching Out!");
        console.log(result.text);
        document.getElementById("messageForm").reset()
      }, (error) => {
          console.log(error.text);
      });
    };
  };

  return (
    <div> 
         {/*Contact Form*/}
        <div className='contactSection'>
        <span style={{marginBottom: '0.3rem'}}> Contact Kushim </span>
          <form ref={form} onSubmit={sendEmail} className='contactForm' id='messageForm'>
          <label>Name:</label>
          <input type="text" name="user_name" style={{backgroundColor: '#dae3c1', borderColor: '#000', marginBottom: '1rem', fontFamily:'sans-serif', fontSize: '15px'}}/>
          <label>Email:</label>
          <input type="email" name="user_email" style={{backgroundColor: '#dae3c1', borderColor: '#000', marginBottom: '1rem', fontFamily:'sans-serif', fontSize: '15px'}}/>
          <label>Message:</label>
          <textarea id='messageField' name="message" style={{backgroundColor: '#dae3c1', borderColor: '#000', borderRadius: '3px', flexDirection: 'row', resize:'none', marginBottom: '1rem', paddingBottom: '7rem', fontFamily:'sans-serif', fontSize: '15px'}}/>
          <input type="submit" value="Send" 
          style={{color: 'black', display: 'flex', alignSelf: 'center'}}/>
          </form>

        </div>
    </div>

  )
}

export default Bookings