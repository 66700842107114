import React from 'react'
import './Projects.css'
import {Swiper, SwiperSlide} from 'swiper/react'
import SA from "../../img/SA.jpg"
import PE from "../../img/PE.jpg"
import TNB from "../../img/TNB.jpg"
import F from "../../img/F.jpg"
import TN from "../../img/TN.jpg"
import Waves from "../../img/Waves.jpg"
import TheCall from "../../img/TheCall.jpg"
import TheChoice from "../../img/TheChoice.jpg"
import TheWay from "../../img/TheWay.jpg"
import TheEscape from "../../img/TheEscape.jpg"
import TheCalm from "../../img/TheCalm.jpg"
import POWER from "../../img/POWER.jpg"
import ALIVE from "../../img/ALIVE.png"
import TheSaint from "../../img/TheSaint.jpg"
import Faithful from "../../img/Faithful.jpg"
import "swiper/css"


const Projects = () => {
  return (
    <div className='projects'>
        {/* heading */}
        <span>Recent</span>
        <span>Projects</span>

        {/* heading */}
        <Swiper
        spaceBetween={50}
        slidesPerView={4}
        grabCursor={true}
        className="projects-slider">

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-SETAPART' target="_blank" rel="noopener noreferrer">
                <img src={SA} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-APartyInEden' target="_blank" rel="noopener noreferrer">
                <img src={PE} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheNightBirds' target="_blank" rel="noopener noreferrer">
                <img src={TNB} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-Waves' target="_blank" rel="noopener noreferrer">
                <img src={Waves} alt="" />
                </a>
            </SwiperSlide>

             <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-THENEW' target="_blank" rel="noopener noreferrer">
                <img src={TN} alt="" />
                </a>
            </SwiperSlide>
            
            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-FREEDOM' target="_blank" rel="noopener noreferrer">
                <img src={F} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheCall' target="_blank" rel="noopener noreferrer">
                <img src={TheCall} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheChoice' target="_blank" rel="noopener noreferrer">
                <img src={TheChoice} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheEscape' target="_blank" rel="noopener noreferrer">
                <img src={TheEscape} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheWay' target="_blank" rel="noopener noreferrer">
                <img src={TheWay} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheCalm' target="_blank" rel="noopener noreferrer">
                <img src={TheCalm} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-POWER' target="_blank" rel="noopener noreferrer">
                <img src={POWER} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-TheSaint' target="_blank" rel="noopener noreferrer">
                <img src={TheSaint} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-Faithful' target="_blank" rel="noopener noreferrer">
                <img src={Faithful} alt="" />
                </a>
            </SwiperSlide>

            <SwiperSlide>
                <a href='https://fanlink.to/KUSHIM-ALIVE' target="_blank" rel="noopener noreferrer">
                <img src={ALIVE} alt="" />
                </a>
            </SwiperSlide>

        </Swiper>
    </div>
  )
}

export default Projects