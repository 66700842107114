import React from 'react';
import Footer from '../components/Footer/Footer'
import Bookings from '../components/Bookings/Bookings'
import './pagestyles/Portfolio.css'
import P1 from '../img/PortImg1.jpg'
import P2 from '../img/PortImg2.jpg'
import P3 from '../img/PortImg3.jpg'
import P4 from '../img/PortImg4.jpg'
import P5 from '../img/PortImg5.jpg'
import P6 from '../img/PortImg6.jpg'
import P7 from '../img/PortImg7.jpg'
import P8 from '../img/PortImg8.jpg'
import P9 from '../img/PortImg9.jpg'

function Portfolio() {

  return (
    <div> <h1 className='h'> Portfolio </h1>
    <h1 className='hsub'> Some projects Kushim was involved in </h1>
    <div className='allPort'>
        <div className='port'>
            <div className='portBox'>
              <a href='https://open.spotify.com/album/1bA4zwAVhRtQdM7pRx2Wpx' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
              <img src={P1} alt=''/>
              <p className='portBoxTextStyle'> "The  Epistel Project" <br/> by IMRSQD <br/><br/> Credit: Record + Mix + Master [All Songs] </p>
            </div>
            
            <div className='portBox'>
            <a href='https://open.spotify.com/track/00xGlTvorRxhTAMacmyABc?si=c21b9beb51734db1' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
            <img src={P2} alt=''/>
              <p className='portBoxTextStyle'> "Praises 24/7"  <br/> by Chiwaz ft Avery Doreen <br/><br/> Credit: Mix + Master [Single]  </p>
            </div>
            
            <div className='portBox'>
            <a href='https://open.spotify.com/track/228eAjn9UfCe8PcNJ79TMN?si=207e81ad936447ca' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
            <img src={P3} alt=''/>
              <p className='portBoxTextStyle'> "Freedom" <br/> by Chiwaz feat. Various Artists <br/><br/> Credit: Mix + Master [Single] </p>
            </div>
         </div>

         <div className='port'>
            <div className='portBox'>
              <a href='https://open.spotify.com/album/4vwTYyHuZpiEb6z1HJjQpS?si=w5nq1BO8ToyxSxg2g9REOA' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
              <img src={P4} alt=''/>
              <p className='portBoxTextStyle'> "Crimson" <br/> by IMRSQD ft Sam Rivera <br/> Credit: (Some) Recordings + Mix + Master [Single] </p>
            </div>
            
            <div className='portBox'>
            <a href='https://open.spotify.com/album/3BqbX60OeR6bGnyCJ4bUkQ?si=dzW6lvAVSzScIJFSkxXyRg' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
            <img src={P5} alt=''/>
              <p className='portBoxTextStyle'> "Ma I Made It"  <br/> by IMRSQD <br/><br/> Credit: Record + Mix + Master [Single]  </p>
            </div>
            
            <div className='portBox'>
            <a href='https://open.spotify.com/track/25jaqTfAg4qNCht0Hut0tI?si=6cd9d22092444f3f' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
            <img src={P6} alt=''/>
              <p className='portBoxTextStyle'> "Tempest" <br/> by IMRSQD <br/><br/>  Credit: Record + Mix + Master [Single] </p>
            </div>
         </div>

         <div className='port'>
            <div className='portBox'>
              <a href='https://open.spotify.com/track/2l9tg89matJIAwyi3ObH6z?si=9e06fda7c6944d87' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
              <img src={P7} alt=''/>
              <p className='portBoxTextStyle'> "Walking Zoo" <br/> by Asham <br/><br/> Credit: Record + Mix + Master [Single] </p>
            </div>
            
            <div className='portBox'>
            <a href='https://open.spotify.com/track/1z9A20oWx4V8AeToIyqnFv?si=08c0d0d934d8473b' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
            <img src={P8} alt=''/>
              <p className='portBoxTextStyle'> "Daddy's Little Girl"  <br/> by Nasim <br/><br/>  Credit: Record + Mix + Master [Single]  </p>
            </div>
            
            <div className='portBox'>
            <a href='https://open.spotify.com/track/1D1xDJJpTkzWspz5vAHnDv?si=d0fa83ce42774958' className='projectLink' target="_blank" rel="noopener noreferrer"> link </a>
            <img src={P9} alt=''/>
              <p className='portBoxTextStyle'> "Know Ya" <br/> by NÜ PEACE ft Xay Hill <br/><br/>  Credit: Mix + Master [Single] </p>
            </div>
         </div>
         </div>
         {/*Contact Form*/}
        <Bookings/>
        <Footer/>
    </div>

  )
}

export default Portfolio