import {themeContext} from './Context'
import { useContext } from "react";
import Home from './pages/Home'
import ProjectPage from './pages/ProjectPage'
import Moon from './pages/Moon'
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import Biography from './pages/Biography';
import Portfolio from './pages/Portfolio';
import BookingPage from './pages/BookingPage';

function App() {

  const theme = useContext(themeContext);
  const darkMode = theme.state.darkMode;

  return (
  <BrowserRouter>
    <div className="App"
      style={{
        background : darkMode? '#121212': '',
        color : darkMode? 'white': '',
        }}>

          <Navbar/>

          <Routes>
            <Route path='/' element={<Home/>} exact/>
            <Route path='/projects' element={<ProjectPage/>} />
            <Route path='/moon' element={<Moon/>} />
            <Route path='/bio' element={<Biography/>} />
            <Route path='/portfolio' element={<Portfolio/>} />
            <Route path='/booking' element={<BookingPage/>} />
          </Routes>
    </div>

  </BrowserRouter>
  );
}

export default App;
