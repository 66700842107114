import React from 'react'
import './Footer.css'
import Instagram from '@iconscout/react-unicons/icons/uil-instagram'
import StreamMusic from '@iconscout/react-unicons/icons/uil-music-note'
import Video from '@iconscout/react-unicons/icons/uil-video'
import Home from '@iconscout/react-unicons/icons/uil-home'
import Bio from '@iconscout/react-unicons/icons/uil-book'
import FooterBackground from '../../img/footerbackground.png'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div className="footer">
        <img src={FooterBackground} alt="" style={{width: '100%'}} />
        <div className="f-content">
            <span> KushimBookings@gmail.com </span>
            <div className="f-icons">

            <Link to='/' className='homeButton'>
                <Home color='white' size='3rem'/>
                </Link>
                
                <Link to='/bio' className='bio'>
                <Bio color='white' size='3rem'/>
                </Link>

                <a href='https://www.instagram.com/kushimofficial/' target="_blank" rel="noopener noreferrer">
                  <Instagram color='white' size='3rem'/>

                </a>

                <a href='https://fanlink.to/KUSHIM' target="_blank" rel="noopener noreferrer">
                  <StreamMusic color='white' size='3rem'/>
                </a>

                <a href='https://www.youtube.com/c/KushimOfficial/featured' target="_blank" rel="noopener noreferrer">
                <Video color='white' size='3rem'/>
                </a>

                <span className='createdByMobileVersion'> Website Created By Kushim </span>
            </div>
            <span className='createdBy'> Website Created By Kushim </span>
        </div>
    </div>
  )
}

export default Footer