import React from 'react'
import Footer from '../components/Footer/Footer'
import './pagestyles/Moon.css'

function Moon() {
  return (
    <div>
        <span className='moon'>
        <div className='box'>
          “You are the light of the world—like a city on a hilltop that cannot be hidden.  <br/>
            No one lights a lamp and then puts it under a basket. Instead,  <br/>
             a lamp is placed on a stand, where it gives light to everyone in the house.   <br/>
              In the same way, let your good deeds shine out for all to see, <br/>
               so that everyone will praise your heavenly Father.    <br/> 
          <br/> 
          - Matthew 5:14-16
        </div>   
        </span>
        <Footer/>

    </div>
  )
}

export default Moon