import React from 'react'
import './Intro.css'
import KushimImage from '../../img/kushimImage.jpg'
import Vinyl from '../../img/vinyl.png'
import DebutEP from '../../img/DebutEP.jpg'
import Moon from '../../img/moon.png'
import { FloatingDiv } from '../FloatingDiv/FloatingDiv'
import {motion} from 'framer-motion'
import {themeContext} from '../../Context'
import { useContext } from "react";
import { Link } from 'react-router-dom'

const Intro = () => {

    const transition = {duration : 2, type: 'spring'}
    const theme = useContext(themeContext);
    const darkMode = theme.state.darkMode;

    return (
        <div className="intro">
            <div className="intro-left">
                <div className="intro-name">
                    <span style={{color: darkMode? 'white': ''}}> Welcome to </span>
                    <h1> Kushim's Official Site </h1>
                    <span> Kushim is an Artist, Producer and Sound Engineer with an alternative sound and 
                            a faith led message which is primarily founded on truth, love, hope & peace.
                    </span>
                </div>
                
                {/*Booking Button*/}
                <Link to="/booking">
                <button className="button intro-button">
                    Booking
                </button>
                </Link>
            </div>
            <div className="intro-right">
                
                <img src={KushimImage} alt=""/>

                <motion.div
                initial={{left: '-30%'}}
                whileInView={{left: '-4%'}}
                transition={transition}> 
                
                <Link to="/moon">
                <img src={Moon} alt='' className='moon'/>
                </Link>
                </motion.div>
                
                <motion.div
                initial={{top: "-4%", left: "74%"}}
                whileInView={{left: "68%" }}
                transition={transition} 
                
                style={{top: '-4%', left: '68%'}}>
                    <a href="https://fanlink.to/KUSHIM-SETAPART" target="_blank" rel="noopener noreferrer">
                    <FloatingDiv image={DebutEP} txt1='Debut EP' txt2='"SET  APART"'/>
                    </a>
                </motion.div>
                
                <motion.div
                initial={{left: "9rem", top: "18rem"}}
                whileInView={{left: "0rem" }}
                transition={transition} 
                style={{top: '18rem', left: '0rem'}}>

                    <Link to="/portfolio">
                    <FloatingDiv image={Vinyl} txt1='Mixing' txt2='Services'/>
                    </Link>

                </motion.div>
                
                {/* blur divs*/}
                <div className="blur" style={{background: "#9AB4DB"}}>
                </div>

                <div className="blur" style={{background: "#9AB4DB", 
                        top: '17rem', 
                        width: '21rem',
                        height: '11rem',
                        left: '-9rem',
                        }}>
                </div>
                    
            </div>
        </div>
    )
}

export default Intro