import React from 'react'
import '../App.css'
import Intro from "../components/Intro/Intro";
import Footer from "../components/Footer/Footer";
import Projects from '../components/Projects/Projects';

const Home = () => {
    
    return (
      <div>
        <Intro/>
        <Projects/>
        <Footer/>
      </div>
    );
}

export default Home