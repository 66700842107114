import React from 'react'
import '../App.css'
import Projects from "../components/Projects/Projects";
import Footer from "../components/Footer/Footer";

const ProjectPage = () => {
    
    return (
      <div>
        <Projects/>
        <Footer/>
      </div>
    );
}

export default ProjectPage