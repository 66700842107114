import React from 'react'
import Footer from '../components/Footer/Footer'
import './pagestyles/Biography.css'

function Biography() {
  return (
    <div> 
        <div className='bioBox textStyle'>
            <h1>
                Kushim bio
            </h1>
            <p>
                  Originally based out of Nigeria, David Kushim John 
                  has been making waves internationally with 
                  an alternative sound and a faith led message which is primarily founded on truth, love, hope & peace. <br/><br/>
                  "Kushim" is a name meaning 'peace'
                  
            </p>
        </div>
        <Footer/>
    </div>
  )
}

export default Biography